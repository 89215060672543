





















import { Component, Vue } from 'vue-property-decorator'
import _ from 'underscore'

@Component
export default class TransactionIndex extends Vue {
  //#region COMPUTED
  get roleByPath() {
    const path = this.$route.path
    const role = this.$store.getters.roles.find((x:any) => x.link == path)
    if (!role) return []
    return this.$store.getters.detailRoles.filter((x:any) => x.trackingRolesId === role.id)
  }

  get groupItems() {
    return _.chain(this.roleByPath)
      .groupBy(x => x.group)
      .map((val, key) => key)
      .value()
  }
  //#endregion
  //#region METHODS
  itemByGroup(group:any) {
    return this.roleByPath.filter((x:any) => x.group === group)
  }
  //#endregion
}
